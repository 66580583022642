<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Tools</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Barcode
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Barcode Generator</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head"></div>
    <div class="modal-dialog-centered center-box">
      <div class="modal-body bg-theam-secondary row">
        <form @submit.prevent="submit" class="row purchase-ht4">
          <div class="col-md-12 p-0">
            <div class="group">
              <div class="group-attribute-container">
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">Branch</div>
                  <div class="col-md-7 ml-0 pl-0">
                    <select
                      v-model="branch"
                      class="form-control"
                      @change="getItems"
                    >
                      <option value="" disabled>All Branch</option>
                      <option
                        v-for="(branch, index) in barcodeBranchs"
                        :key="index"
                        :value="branch.id"
                      >
                        {{ branch.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">Item</div>
                  <div class="col-md-7 ml-0 pl-0">
                    <select v-model="item" class="form-control" @change="getPurchaseIds" required>
                      <option value="" disabled>Select Item</option>
                      <option
                        v-for="(item, index) in barcodeItems"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">Purchase ID</div>
                  <div class="col-md-7 ml-0 pl-0">
                    <select v-model="purchaseId" class="form-control" @change="getStockData" required>
                      <option value="" disabled>Select Purchase Id</option>
                      <option
                        v-for="(purchase, index) in barcodePurchases"
                        :key="index"
                        :value="purchase.purchase_id"
                      >
                        {{purchase.purchase.prefix }}{{ purchase.purchase_id }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">Stock Available</div>
                  <div class="col-md-7 ml-0 pl-0">
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      v-model="stock"
                      required
                    />
                  </div>
                </div>
                <input
                  type="hidden"
                  class="form-control"
                  disabled
                  v-model="barcode"
                />
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">Print Count</div>
                  <div class="col-md-7 ml-0 pl-0">
                    <input
                      type="number"
                      class="form-control"
                      v-model="print_count"
                      required
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">Print Type</div>
                  <div class="col-md-7 ml-0 pl-0">
                    <select
                      v-model="printer_type"
                      class="form-control"
                      required
                    >
                    <option value="" disabled>Select Printer Type</option>
                      <option value="a4_printer">A4 Sheet Printer</option>
                      <option value="barcode_printer">Barcode Printer</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-right mt-2 pr-0">
            <button type="submit" class="btn btn-sm btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Service";
export default {
  data() {
    return {
      item: "",
      purchaseId: "",
      stock: "",
      barcode: "",
      print_count: "",
      printer_type: "",
      branch: "",
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("barcode", ["barcodeBranchs", "barcodeItems","barcodePurchases"]),
  },
   beforeMount() {
    this.getBranch();
    this.getItems();
  },
  methods: {
    getItems() {
      this.purchaseId = "";
      this.item = "",
      this.stock="";
      this.print_count="";
      Services.getBarcodeItems(this.branch)
        .then((res) => {
          this.$store.commit("barcode/setBarcodeItems", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStockData(){
      let purchaseId = this.purchaseId;
      let details = this.barcodePurchases.find(function (data) {
              return purchaseId == data.purchase_id;
          });
      this.stock = details.available;
      this.print_count = details.available;
      this.barcode = details.barcode;
    },
    submit() {
      let datas = {
        stock:this.stock,
        printer_type:this.printer_type,
        print_count:this.print_count,
        purchaseId:this.purchaseId,
        barcode:this.barcode,
      }
      this.$store.commit("setBarcodeData", datas);
      this.$router.push(`/barcode/view`);
    },
    getBranch(){
      Services.getUserAccessBranches()
      .then((res) => {
        this.$store.commit("barcode/setBarcodeBranchs", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getPurchaseIds(){
      let params = {
        branch:this.branch,
        item : this.item,
      };
     Services.getBarcodePurchaseIds(params).then(res=>{
       this.$store.commit("barcode/setBarcodePurchases",res.data.data);
     }).catch(err=>{
       console.log(err);
     });
    }
  },
 
  // watch: {
  //   "formData.item"(value) {
  //     this.$store.commit("getData4", `api/purchaseItem/${value}`);
  //   },
  //   "formData.purchaseId"(value) {
  //     console.log(value);
  //     let details = this.dataLists4.filter(function (data) {
  //       return data.id == value;
  //     });

  //     this.formData.stock = details[0].available;
  //     this.formData.barcode = details[0].barcode;
  //   },
  // },
};
</script>
<style>
</style>